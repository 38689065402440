//------------------------------------------------------------
// = SIDEBAR
//------------------------------------------------------------
.sidebar{
  grid-area: sidebar;
  background-color: var(--color-bg-pale);
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  @media screen and (max-width: 992px) {
    border-radius: 0;
  }
}
.sidebar__inner{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  @media screen and (max-width: 992px) {
    padding: 0;
  }
}


//------------------------------------------------------------
// = HEADER
//------------------------------------------------------------
header{
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 60px;
  border-bottom: 1px solid var(--color-border);
  @media screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 992px) {
    padding: 15px;
    margin-bottom: 0;
  }
}
.navigation__toggle{
  @extend .button-reset;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--color-bg);
  transition: var(--transition);
  cursor: pointer;
  @media screen and (min-width: 993px) {
    display: none;
  }
}
.toggle__span{
  position: relative;
  display: block;
  width: 16px;
  height: 2px;
  margin: 2px;
  background: var(--color-paragraph);
  transition: var(--transition);
  .is-active &{
    &:nth-of-type(1){
      transform: rotateZ(45deg);
      top: 6px;
    }
    &:nth-of-type(2){
      transform: translateX(100%);
      opacity: 0;
    }
    &:nth-of-type(3){
      transform: rotateZ(-45deg);
      top: -6px;
    }
  }
}

.logo{
  display: block;
  &:hover{
    #logomark{
      transform: translateY(2px);
    }
  }
  svg, img{
    max-width: 100%;
    #logomark{transition: var(--transition);}
  }

  header & {
    margin: auto;
    width: 100%;
    text-align: center;
    align-self: center;
    @media screen and (max-width: 992px) {
      max-width: 120px;
      @include centerer();
    }
  }
}


//------------------------------------------------------------
// = ASIDE
//------------------------------------------------------------
aside{
  margin-bottom: 60px;
  transition: var(--transition);
  @media screen and (min-width: 1366px) {
    position: sticky;
    top: 30px;
  }
  @media screen and (max-width: 1366px) {
    top: 15px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 992px) {
    position: absolute;
    top: 73px;
    left: 0;
    width: 100%;
    padding: 15px 6px;
    min-height: 100vh;
    z-index: 100;
    background: var(--color-bg);
    box-shadow: var(--shadow-2);
    transform: translateX(-100%);
  }
  &.is-active{
    transform: translateX(0);
  }
}


//------------------------------------------------------------
// = FOOTER
//------------------------------------------------------------
footer{
  padding-top: 15px;
  margin-top: auto;
  border-top: 1px solid var(--color-border);
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.copyright{
  @extend %fs14;
  color: var(--color-paragraph-light);
}