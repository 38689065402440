//------------------------------------------------------------
// = LAYOUTS
//------------------------------------------------------------
.layout{
  display: grid;
  grid-template-areas:  "sidebar main";
  grid-template-columns: minmax(220px, 260px) minmax(720px, 100%);
  grid-gap: 30px;
  max-width: 1410px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1366px) {
    padding: 15px;
    grid-gap: 15px;
  }
  @media screen and (max-width: 992px) {
    grid-template-columns: 100%;
    grid-template-areas: "sidebar" "main";
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  &:not(.layout--homepage){
    @media screen and (max-width: 768px) {
      grid-gap: 0;
    }
  }
}