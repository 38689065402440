//------------------------------------------------------------
// = PAGE
//------------------------------------------------------------
.page{
  background-color: var(--color-bg-pale);
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  overflow: hidden;
  height: 100%;
  @media screen and (max-width: 768px) {
    border-radius: 0;
  }
}
.page_head{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 30px;
  border-bottom: 1px solid var(--color-border);
  @media screen and (max-width: 992px) {
    padding: 15px;
  }
}
.page__head-v2{
  background-size: cover;
  text-align: center;
  min-height: 220px;
  @media screen and (max-width: 768px) {
    min-height: 90px;
  }
}
.page__title{
  @extend %fs28;
  margin-bottom: 5px;
  color: var(--color-paragraph);
  font-weight: var(--semi-bold);
  .page__head-v2 & {
    color: var(--color-white);
  }
}
.page__description{
  @extend %fs16;
  font-weight: var(--light);
  color: var(--color-paragraph-light);
  @media screen and (max-width: 992px) {
    @include fontsize(1.4);
  }
  .page__head-v2 & {
    color: var(--color-white);
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
.page_content{
  padding: 30px;
  @media screen and (max-width: 992px) {
    padding: 15px;
  }
}
