//------------------------------------------------------------
// = MAIN
//------------------------------------------------------------
.main{
  grid-area: main;
  &.main--homepage{
    @media screen and (max-width: 768px) {
      padding: 0 15px;
    }
  }
}