//------------------------------------------------------------
// = LAYOUT
//------------------------------------------------------------
fieldset{
  margin: 0 0 30px;
  padding: 0;
  border: 0;
}
legend{
  @extend %fs14;
  margin-bottom: 15px;
  padding-left: 0;
  color: var(--color-primary);
  font-weight: var(--regular);
  letter-spacing: 0.05em;
}
.form-row{
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr;
  margin-bottom: 30px;
  @media screen and (max-width: 992px) {
    margin-bottom: 15px;
  }
  &.span-6{
    grid-template-columns: repeat(2, 1fr);
  }
  &.span-8-4{
    grid-template-columns: 2fr 1fr;
  }
  &.check-row{
    grid-template-columns: repeat(3, 1fr);
  }
  &[class*="span-"]{
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  label{
    position: relative;
    display: block;
  }
  .input-group{
    position: relative;
    .select-arrow{
      position: absolute;
      top: 50%;
      right: 15px;
      width: 10px;
      height: 10px;
      color: var(--color-paragraph);
      background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI1NiAyNTYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NiAyNTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwb2x5Z29uIHBvaW50cz0iMjI1LjgxMyw0OC45MDcgMTI4LDE0Ni43MiAzMC4xODcsNDguOTA3IDAsNzkuMDkzIDEyOCwyMDcuMDkzIDI1Niw3OS4wOTMgICAiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
      background-size: contain;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  &.cta-row{
    padding-top: 30px;
  }
}
%form-label{
  @extend %fs14;
  display: block;
  margin-bottom: 5px;
  color: var(--color-paragraph-light);
}
%input-element{
  @extend %fs16;
  border: 1px solid var(--color-border);
  border-radius: var(--radius-2);
  width: 100%;
  padding: 0 15px;
  color: var(--color-paragraph);
  transition: var(--transition);
  background: var(--color-bg-pale);
  &::placeholder{color: var(--color-paragraph-light);}
  &:hover{border-color: var(--color-primary);}
  &:focus{border-color: var(--color-primary);}
}

//------------------------------------------------------------
// = ELEMENT
//------------------------------------------------------------
.input-1{
  .label{@extend %form-label;}
  input{
    @extend %input-element;
    height: var(--input-height);
  }
  textarea{
    @extend %input-element;
    height: var(--textarea-height);
    font-family: var(--font);
    padding: 15px;
  }
}
.select-1{
  .label{@extend %form-label;}
  select{
    @extend %input-element;
    height: var(--input-height);
  }
}
.check-1{
  margin-bottom: 30px;
  label{
    position: relative;
    cursor: pointer;
    input{
      display: none;
      & ~ .label{
        @extend %fs16;
        display: inline-block;
        position: relative;
        padding: 4px 0 0 47px;

        font-weight: var(--regular);
        color: var(--color-paragraph-light);
        text-align: left;
        a{color: var(--color-success);}
        &:after{
          content: '';
          position: absolute;
          width: 32px;
          height: 32px;
          background-color: var(--color-bg-pale);
          top: 0;
          left: 0;
          border: 1px solid var(--color-border);
          transition: var(--transition);
        }
      }
      &:checked ~ .label{
        &:after{
          border-color: var(--color-success);
          background-color: var(--color-success);
          box-shadow: inset 0 0 0 4px var(--color-bg-pale);
        }
      }
      &:disabled ~ .label{
        &:after {
          opacity: .5;
        }
      }
      &:checked:disabled ~ .label{
        &:before {
          transform: scale(1);
        }
        &:after {
          opacity: 1;
        }
      }

      &[type="radio"] ~ .label{
        &:after{border-radius: 50%;}
      }
      &[type="checkbox"] ~ .label{
        &:after{border-radius: var(--radius)}
      }
    }
  }
}
.check-2{
  label{
    cursor: pointer;
    height: 100%;
    input{
      display: none;
      & ~ .check-content{
        position: relative;
        height: 100%;
        padding: 15px;
        border: 1px solid var(--color-border);
        border-radius: var(--radius-2);
        background: var(--color-bg-pale);
        box-shadow: var(--shadow-1);
        transition: var(--transition);
        @media screen and (max-width: 768px) {
          padding: 10px;
        }
        &:hover{
          box-shadow: var(--shadow-2);
        }
        &:before{
          position: absolute;
          right: 15px;
          top: 15px;
          content: '';
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 1px solid var(--color-border);
          background: none;
          transition: var(--transition);
          @media screen and (max-width: 768px) {
            width: 16px;
            height: 16px;
            right: 10px;
            top: 10px;
          }

        }
        .title{
          @extend %fs14;
          margin-bottom: 10px;
          padding: 4px 30px 0 0;
          color: var(--color-paragraph);
          font-weight: var(--semi-bold);
          @media screen and (max-width: 768px) {
            padding: 15px 0 0 0;
          }
        }
        .description{
          @extend %fs14;
          @include truncate(3);
          margin-bottom: 15px;
          color: var(--color-paragraph);
          font-weight: var(--light);
        }
        .price{
          @extend %fs16;
          color: var(--color-primary);
          font-weight: var(--semi-bold);
        }
        .figure{
          max-width: 64px;
          max-height: 64px;
          object-fit: scale-down;
          @media screen and (max-width: 768px) {
            max-width: 32px;
            max-height: 32px;
          }
        }
      }
      &:checked ~ .check-content{
        border-color: var(--color-success);
        &:before{
          background: url("data:image/svg+xml;utf8,<svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
<path d='M0.207692 7.7C0.0692308 7.56 0 7.35 0 7.21C0 7.07 0.0692308 6.86 0.207692 6.72L1.17692 5.74C1.45385 5.46 1.86923 5.46 2.14615 5.74L2.21538 5.81L6.02308 9.94C6.16154 10.08 6.36923 10.08 6.50769 9.94L15.7846 0.21H15.8538C16.1308 -0.07 16.5462 -0.07 16.8231 0.21L17.7923 1.19C18.0692 1.47 18.0692 1.89 17.7923 2.17L6.71538 13.79C6.57692 13.93 6.43846 14 6.23077 14C6.02308 14 5.88462 13.93 5.74615 13.79L0.346154 7.91L0.207692 7.7Z' fill='white'/>
</svg>");
          background-color: var(--color-success);
          background-repeat: no-repeat;
          background-position: center;
          border-color: var(--color-success);
          @media screen and (max-width: 768px) {
            background-size: contain;
          }
        }
      }
    }
  }
}