html {
  //------------------------------------------------------------
  // = GENERAL
  //------------------------------------------------------------
  --font:                   'Poppins', sans-serif;
  --semi-bold:              600;
  --regular:                400;
  --light:                  300;

  --transition:             all .15s ease;
  --radius:                 12px;
  --radius-2:               6px;
  --shadow-1:               2px 2px 30px rgba(0, 0, 0, 0.02);
  --shadow-2:               4px 4px 60px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 768px) {
    --radius:                 4px;
    --radius-2:               4px;
  }


  //------------------------------------------------------------
  // = SIZE
  //------------------------------------------------------------
  --input-height:           50px;
  --textarea-height:        150px;


  //------------------------------------------------------------
  // = COLOR
  //------------------------------------------------------------
  --color-bg:               #F7F7F7;
  --color-bg-alternate:     #F8F8F8;
  --color-bg-pale:          #FFFFFF;
  --color-border:           #EDEDED;

  --color-primary:          #202C96;
  --color-primary-alt:      #2837c1;

  --color-paragraph:        #0A0D37;
  --color-paragraph-alt:    #6c6c6c;
  --color-paragraph-light:  #757373;

  --color-highlight:        #FFE500;
  --color-success:          #36B37E;
  --color-alert:            #FF5630;

  --color-white:            #FFFFFF;
  --color-black:            #0A0D37;

}

//------------------------------------------------------------
// = SCSS
//------------------------------------------------------------
$layout:              "../images/layout/";