//------------------------------------------------------------
// = MIXIN
//------------------------------------------------------------

// Truncate
@mixin truncate($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Absolute Center
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translateX(-50%);
  } @else if ($vertical) {
    top: 50%;
    transform: translateY(-50%);
  }

}


//------------------------------------------------------------
// = KEYFRAMES
//------------------------------------------------------------