//------------------------------------------------------------
// = SECTION
//------------------------------------------------------------
.section{
  & + .section{
    margin-top: 30px;
  }
}
.section__title{
  @extend %fs18;
  font-weight: var(--semi-bold);
  margin-bottom: 15px;
}


//------------------------------------------------------------
// = NAVIGATION
//------------------------------------------------------------
.navigation{
  & + .navigation{
    aside & {
      margin-top: 60px;
      @media screen and (max-width: 1366px) {
        margin-top: 30px;
      }
    }
  }
}
.navigation__title{
  @extend %fs14;
  margin-bottom: 15px;
  color: var(--color-paragraph-light);
  font-weight: var(--semi-bold);
  letter-spacing: 0.05em;
  padding: 0 10px;
}
.navigation__group{

}
.navigation__item{
  & + .navigation__item{
    margin-top: 12px;
    @media screen and (max-width: 992px) {
      margin-top: 6px;
    }
  }
  a{
    @extend %fs16;
    color: var(--color-paragraph);
    font-weight: var(--light);
    text-decoration: none;
    border-radius: var(--radius-2);
    padding: 4px 10px;
    @media screen and (max-width: 992px) {
      display: block;
    }
    &:hover{
      background-color: var(--color-bg-alternate);
    }
  }
}


//------------------------------------------------------------
// = HERO
//------------------------------------------------------------
.hero{
  background-color: var(--color-bg-pale);
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  margin-bottom: 30px;
}
.hero__wrapper{}
.hero__item{
  a{
    display: block;
  }
  img{
    display: block;
    width: 100%;
    height: 100%;
  }
}


//------------------------------------------------------------
// = CARDS
//------------------------------------------------------------
.card-container{
  display: grid;
  grid-gap: 15px;
  &.item-3{
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &.item-4{
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.card-product{
  background-color: var(--color-bg-pale);
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  overflow: hidden;
  transition: var(--transition);
  &:hover{
    box-shadow: var(--shadow-2);
  }
  a{
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }
}
.card-product__figure{
  line-height: 0;
  max-height: 260px;
  overflow: hidden;
  img{
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    transition: var(--transition);
    .card-product:hover & {
      transform: scale(1.1);
    }
  }
}
.card-product__body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 15px;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }
}
.card-product__title{
  @extend %fs18;
  font-weight: var(--semi-bold);
  color: var(--color-paragraph);
  margin-bottom: 5px;
  @media screen and (max-width: 768px) {
    @include fontsize(1.4);
  }
  .card-product:hover & {
    color: var(--color-primary);
  }
}
.card-product__description{
  @extend %fs14;
  font-weight: var(--regular);
  color: var(--color-paragraph-light);
  margin-top: auto;
}

.card-howto-container{
  counter-reset: stepCounter;
}
.card-howto{
  display: flex;
  flex-direction: column;
  align-items: center;
  counter-increment: stepCounter;
  background-color: var(--color-bg-pale);
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  overflow: hidden;
  transition: var(--transition);
  padding: 0 15px 30px;
  text-align: center;
  &:before{
    content: counter(stepCounter);
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: var(--color-bg-alternate);
    border-radius: 50%;
    padding: 15px;
    font-size: 4rem;
    margin-top: -90px;
    margin-bottom: 30px;
    @media screen and (max-width: 1366px) {
      width: 128px;
      height: 128px;
      margin-top: -64px;
      margin-bottom: 15px;
      font-size: 3rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      width: 64px;
      height: 64px;
      margin-top: -32px;
      padding: 5px;
    }
  }
}
.card-howto__figure{
  max-width: 100%;
  margin-bottom: 30px;
  @media screen and (max-width: 1366px) {
    margin-bottom: 15px;
    max-width: 62px;
  }
}
.card-howto__title{
  @extend %fs16;
  font-weight: var(--semi-bold);
  color: var(--color-paragraph);
  @media screen and (max-width: 768px) {
    @include fontsize(1.4);
  }
}

.product__summary{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  margin-left: -5px;
  margin-right: -5px;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}
.summary__item{
  @extend %fs14;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-1);
  background-color: var(--color-bg-pale);
  padding: 5px 15px;
  border-radius: var(--radius-2);
  margin: 5px;
  @media screen and (max-width: 768px) {
    @include fontsize(1.2);
    padding: 2px 6px;
  }

  &.summary__item-stock{
    margin-left: auto;
    text-align: right;
    @media screen and (max-width: 768px) {
      order: -1;
      margin-left: 5px;
      flex: 100%;
      text-align: center;
    }
    &.in-stock{
      background-color: var(--color-success);
      border-color: var(--color-success);
    }
    &.out-stock{
      background-color: var(--color-alert);
      border-color: var(--color-alert);
    }
  }
}
.summary__item-title{
  font-weight: var(--regular);
  color: var(--color-paragraph-light);
  .summary__item-stock & {color: var(--color-white);}
}
.summary__item-value{
  font-weight: var(--semi-bold);
  color: var(--color-paragraph);
  .summary__item-stock & {color: var(--color-white);}
}

//------------------------------------------------------------
// = ACCORDION
//------------------------------------------------------------
.accordion-content{
  .item{
    .item-head{
      @extend %fs16;
      position: relative;
      padding: 15px 45px 15px 0;
      color: var(--color-paragraph);
      font-weight: var(--semi-bold);
      transition: var(--transition);
      &:after{
        @include centerer(false, true);
        content: "";
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        font-size: 2rem;
        text-align: center;
        transition: var(--transition);
        background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI1NiAyNTYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NiAyNTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxwb2x5Z29uIHBvaW50cz0iMjI1LjgxMyw0OC45MDcgMTI4LDE0Ni43MiAzMC4xODcsNDguOTA3IDAsNzkuMDkzIDEyOCwyMDcuMDkzIDI1Niw3OS4wOTMgICAiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
        background-size: cover;
      }
      &:hover{
        background: var(--color-bg-alternate);
        cursor: pointer;
      }
      &.is-active{
        color: var(--color-primary);
        &:after{
          transform: translateY(-50%) rotateZ(180deg);
        }
      }
    }
    .item-content{
      @extend %fs16;
      display: none;
      padding: 15px 0;
      font-weight: var(--light);
      color: var(--color-paragraph);
      > * {
        & + * {
          margin-top: 15px;
        }
      }
    }
  }
}


//------------------------------------------------------------
// = FORM LAYOUT
//------------------------------------------------------------
.form__container{
  max-width: 560px;
  margin: 0 auto;
}


//------------------------------------------------------------
// = WYSIWYG
//------------------------------------------------------------
.wysiwyg{
  @extend %fs16;
  font-weight: var(--light);
  color: var(--color-paragraph);
  h1{@extend %fs28;}
  h2, h3{@extend %fs18;}
  h4, h5, h6{@extend %fs16;}
  > * {
    & + * {
      margin-top: 15px;
    }
  }
}