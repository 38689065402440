//------------------------------------------------------------
// = TYPOGRAPHY
//------------------------------------------------------------
@mixin fontsize($size:1, $line: $size * 1.6) {
  font-size: $size + rem;
  line-height: $line + rem;
}

%fs14 {@include fontsize(1.4);}
%fs16 {@include fontsize(1.6);}
%fs18 {
  @include fontsize(1.8);
  @media screen and (max-width: 992px) {
    @include fontsize(1.6);
  }
}
%fs20 {
  @include fontsize(2.0);
  @media screen and (max-width: 992px) {
    @include fontsize(1.8);
  }
}
%fs28 {
  @include fontsize(2.8);
  @media screen and (max-width: 992px) {
    @include fontsize(2.2);
  }
}