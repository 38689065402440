//------------------------------------------------------------
// = BUTTON
//------------------------------------------------------------
.button-reset {
  border: none;
  background: none;
  padding: 0;
}

.button-base {
  position: relative;

  display: inline-block;
  vertical-align: top;
  padding: 15px 30px;
  min-width: 180px;

  font-size: 16px;
  font-weight: var(--regular);
  text-align: center;
  letter-spacing: 0.02em;

  border: none;
  border-radius: var(--radius-2);
  overflow: hidden;
  box-shadow: var(--shadow-1);

  transition: var(--transition);
  will-change: transform;
  backface-visibility: hidden;
  white-space: nowrap;
  transform-origin: 50% 50%;
  cursor: pointer;

  &.disabled, &:disabled {
    cursor: default !important;
  }

  &.button-block {
    display: block;
    width: 100%;
  }

  &.button-sm {
    font-size: 14px;
    padding: 10px 30px;
    min-width: 0;
  }
  &.button-xs {
    padding: 5px 10px;
    min-width: 0;
    font-size: 13px;
  }
}

.button-1 {
  @extend .button-base;
  color: var(--color-white);
  background-color: var(--color-primary);

  &:hover, &:focus {
    color: var(--color-white);
    background-color: var(--color-primary-alt);
  }
}
.button-2{
  @extend .button-base, .button-sm;
  width: max-content;
  color: var(--color-paragraph);
  background-color: var(--color-bg-pale);
  border: 1px solid var(--color-border);
  &:hover, &:focus {
    border-color: var(--color-paragraph-light);
  }
}